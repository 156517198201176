import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/carehomemedicines.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Prescriptionhub = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Prescription Hub"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
            
              <p className="text-size-16">
              The Prescription Hub is your central solution for streamlined ordering and management of repeat prescriptions for patients. Operating through telephone and online channels, our hub efficiently processes prescription orders with the expertise of trained prescription coordinators. Any medication queries or advice are handled by our dedicated team of Pharmacy Technicians or Clinical Pharmacists. Working on behalf of GP practices, we ensure seamless prescription management, empowering patients to access their medications conveniently and efficiently. 
</p>



              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Prescriptionhub;
