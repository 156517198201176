import React, { useEffect, useState } from 'react';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import processLeftImg from '../../assets/images/Homeimages/3.png';
import CryptoJS from 'crypto-js';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import projectImg1 from '../../assets/images/Homeimages/project-img1.jpg';
import projectImg2 from '../../assets/images/Homeimages/project-img2.jpg';
import projectImg3 from '../../assets/images/Homeimages/project-img3.jpg';
import projectImg4 from '../../assets/images/Homeimages/project-img4.jpg';
import projectImg5 from '../../assets/images/Homeimages/project-img5.jpg';
import projectImg6 from '../../assets/images/Homeimages/project-img6.jpg';
import CounterSection from '../counterSection';
import TestimonialSection from '../testiminials';
import { Link } from 'react-router-dom';

const LpcFerderate = () => {
  const [data, setData] = useState([]);


  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };
  useEffect(() => {
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://local-primary-care.webitaltech.com/api/Get-LPC-Data.php?action=getContentSections`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data[3]);
      

            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
  
      fetchData();
    }, []);
    console.log(data);
    
    const projects = [
        { img: projectImg1, category: '', title: 'Manage Your Profile And Apply For Jobs.', description: '', link: 'https://lpcfederate.co.uk/about.html' },
        { img: projectImg2, title: 'Network With Other Professionals.', link: 'https://lpcfederate.co.uk/about.html' },
        { img: projectImg3, title: 'Manage Finances and Accounting.', link: 'https://lpcfederate.co.uk/about.html' },
        { img: projectImg4, title: 'Store, Verify, Access and Share Documents.',link: 'https://lpcfederate.co.uk/about.html' },
        { img: projectImg5, title: 'Manage Allocated Jobs and Shifts.', link: 'https://lpcfederate.co.uk/about.html'},
        { img: projectImg6,title: 'Safely Interact Over A Secure Platform.', link: 'https://lpcfederate.co.uk/about.html' },
    ];
    const replaceSpecialCharacters = (text) => {
      if (!text) return text;
    
      // Replace specific characters that have turned into '?'
      return text
        .replace(/\?/g, "'")  // Replace ? with single quote
        .replace(/â€™/g, "'")  // Replace encoding issues for single quote
        .replace(/â€œ/g, '"')  // Replace encoding issues for double quote
        .replace(/â€/g, '"');  // Handle any other character issues
    };
    return (
      <>
  
  <section id='contact' className="process-main-section w-100 float-left padding-top padding-bottom contact-main-sec">
            <div className="container">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700">{data?.section}</h2>
          </div>
                <div className="process-inner-con">
                    <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src={processLeftImg} alt="process-left-img" />
                        </figure>
                    </div>
                    <div className="process-right-sec mt-5">
  <div className="generic-title">
  <h2 data-aos="fade-up" data-aos-duration="700">{data?.heading}</h2>
  </div>

  <div dangerouslySetInnerHTML={{ __html:replaceSpecialCharacters(data?.description)}}/>
  {/* <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
     
      <div className="process-text-con" >
        <p>With the help of experts in developing technology platforms and solutions, LPC have designed a bespoke digital solution to support and sustain the future for the Primary Care workforce and GP services in Blackburn with Darwen.</p>
      </div>
    </li>
  
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
        <h4>Primary Care Staff:</h4>
        <p>
        Including Doctors, Advanced Nurse Practitioners, Practice Nurses, Clinical Pharmacists, Healthcare Assistants and Admin Staff.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
        <h4>GP Surgeries</h4>
        <p>
        Each surgery will be provided with a unique login where they can manage their surgery’s profile, post jobs, view staff availability, request support from our Expert Team and much more.
        </p>
      </div>
    </li>
  </ul> */}

</div>

                </div>
            </div>
        </section>


  {/* <section className="projects-section w-100 float-left padding-top light-bg">
            <div className="container">
                <div className="generic-title text-center">
              
                    <h2 data-aos="fade-up" data-aos-duration="700">Core Benefits</h2>
                </div>
                <div className="projects-outer-con">
                    {projects.map((project, index) => (
                        <div className="project-box" key={index} data-aos="fade-up" data-aos-duration="700">
                            <div className="project-img">
                                <figure className="mb-0">
                                    <img src={project.img} alt={`project-img${index + 1}`} />
                                </figure>
                            </div>
                            <div className="project-detail">
                                <span className="d-block">{project.category}</span>
                                <h4>{project.title}</h4>
                                <p>{project.description}</p>
                                <Link to={project.link} target='_blank'><i className="fas fa-angle-right"></i></Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section> */}
      </>
    );
};

export default LpcFerderate;
