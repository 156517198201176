import React, { useEffect } from 'react';
import claims from '../assets/images/Homeimages/claims.jpg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import "./services.css"
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
// import '../assets/css/shop.css';

import img1 from "../assets/images/Homeimages/about-img.jpg"
import img2 from "../assets/images/Homeimages/about-video-img.jpg"
import img3 from "../assets/images/video-play-icon.png"
import PortfolioCarousel from './careServices/careservices';
import ServiceCarousel from './Whatwecando';
import AOS from 'aos';
import ProjectCarousel from './latestProjects';
import { Link } from 'react-router-dom';
function ServiceSection() {
    useEffect(() => {
        AOS.init({
            //  duration: 1000,
             once: false,
           })
     }, [])

    //  function lightbox_open() {
    //     document.getElementById('lightbox').style.display = 'block';
    //   }
      
    //   function lightbox_close() {
    //     document.getElementById('lightbox').style.display = 'none';
    //   }
      

    return (
        <>
        <section className="service-main-section w-100 float-left padding-top padding-bottom light-bg">
            <div className="container">
                <div className="generic-title text-center">
                    {/* <span className="small-text"  data-aos-duration="700"></span> */}
                    <h2 className="mb-0"  data-aos-duration="700">
                    Services We Provide
                        </h2>
                </div>
               <PortfolioCarousel />
            </div>
            
        </section>

      
        <section className="index3-service-section w-100 float-left padding-top padding-bottom">
      <div className="container">
        <div className="generic-title text-center">
          <h2 data-aos="fade-up" className='' style={{color: "black"}} data-aos-duration="700">Our Member Practices</h2>
        </div>
        <ServiceCarousel />
        </div>
        </section>

        {/* ABOUT SECTION START */}
{/* <section className="about-main-section padding-top w-100 float-left position-relative">
  <div className="container position-relative">
    <div className="about-inner-con">
      <div className="about-img-con" data-aos="fade-up" data-aos-duration={700}>
        <figure className="mb-0">
          <img src={img1} alt="about-img" />
        </figure>
      </div>
      <div className="about-txt-con">
        <div className="generic-title">
          <span className="small-text" data-aos="fade-up" data-aos-duration={700}>About Us</span>
          <h2 className="mb-0" data-aos="fade-up" data-aos-duration={700}>Solving Problems That are Beyond your Control</h2>
        </div>
        <p data-aos="fade-up" data-aos-duration={700}>Quis nostrud exercitation ullamco laboris nisi aut aliquio modo consequat ruis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
        <ul className="list-unstyled" data-aos="fade-up" data-aos-duration={700}>
          <li>Psychodynamic Therapy</li>
          <li>Free Consultants</li>
          <li>Couple Problem Therapy</li>
          <li>Mental Satisfications</li>
        </ul>
        <div className="generic-btn" data-aos="fade-up" data-aos-duration={700}>
          <Link to="/about">Read More</Link>
        </div>
      </div>
    </div>
    <div className="about-video-box">
      <figure className="mb-0">
        <img src={img2} alt="about-video-img" />
      </figure>

    </div>
  </div>
</section> */}

  {/* ABOUT SECTION END */}


  {/* <section className="w-100 float-left contact-con position-relative">
  <div className="contact-inner-con position-relative" style={{paddingTop: "70px", paddingBottom: "50px", textAlign: "justify", textJustify:"inter-word"}}>
    <div className="container">
      <div className="contact-form">
        
  <p data-aos="fade-up" data-aos-duration={700}></p>
  <p>
    <b>Work with Us:</b> <br />
    LPC works together to achieve core values. We try to maximise all our
    experience, accumulated potential and knowledge of modern medicine to
    achieve excellent primary care services to ensure sustainability.
  </p>
  <p>
    <b>Professional Expertise:</b> <br />
    Our federation comprises a highly skilled and experienced clinical and
    non-clinical workforce committed to ongoing local primary care development.
  </p>
  <p>
    <b>Innovation and Technology:</b> <br />
    We harness the power of modernised healthcare technologies to enhance
    patient care and streamline practice management. From bespoke digital health
    tools to cutting-edge management methods, we value innovation.
  </p>
  <p>
    <b>Collaborative Network:</b> <br />
    By fostering a culture of collaboration among our member practices, we
    facilitate sharing knowledge and expertise. This collective approach
    enhances the quality of care and drives continuous improvement across our
    network.
  </p>
  <p>
    <b>Comprehensive Support:</b> <br />
    Our federation provides extensive support to our partner, including access
    to resources, training, and practice management assistance.
  </p>
  <p />


      </div>
    </div>
  </div>
</section> */}

{/* <section className="project-main-section w-100 float-left padding-top light-bg">
      <div className="container">
        <div className="generic-title text-center">
          <span className="small-text" data-aos="fade-up" data-aos-duration="700">Latest Projects</span>
          <h2 className="mb-0" data-aos="fade-up" data-aos-duration="700">Explore Our Latest Case Studies</h2>
        </div>
<ProjectCarousel/>
</div>
</section> */}
  </>
        
    );
}

export default ServiceSection;
