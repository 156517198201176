import React from 'react'
import LpcFerderate from '../Components/LpcFederate'
import CaseStudy from '../Components/Casestudy'

const CaseStudies = () => {
  return (
    <>
    <CaseStudy/>
    </>
  )
}

export default CaseStudies