// import React from 'react'
// import LpcFerderate from '../Components/LpcFederate'

// const LpcFerderatee = () => {
//   return (
//     <>
//     </>
//   )
// }

// export default LpcFerderatee


import React from 'react'
import Header from '../Components/Header'
import BannerSection from '../Components/Banner'

import CounterSection from '../Components/counterSection'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import ProcessContSection from '../Components/formContcomp'
import TeamSection from '../Components/teamSection'
import TestimonialSection from '../Components/testiminials'
import FooterSection from '../Components/Footer'
import LpcFerderate from '../Components/LpcFederate'
import ServiceSectionfed from '../Components/servicefederate'
import BannerSectionfed from '../Components/Bannerfed'


const LpcFerderatee = () => {
  return (
    <>
    <BannerSectionfed />
<LpcFerderate/>

    <ServiceSectionfed/> 
    {/* <CounterSection/> */}
    {/* <ProcessContSection/> */}
    {/* <TeamSection/> */}
    {/* <TestimonialSection/> */}
    </>
  )
}

export default LpcFerderatee