import React, { useEffect, useState } from 'react';
import counterImg1 from '../../assets/images/counter-img1.png';
import counterImg2 from '../../assets/images/counter-img2.png';
import counterImg3 from '../../assets/images/counter-img3.png';
import counterImg4 from '../../assets/images/counter-img4.png';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import CryptoJS from 'crypto-js';


const CounterSection = () => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);


  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };

    
  useEffect(() => {
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://local-primary-care.webitaltech.com/api/Get-LPC-Data.php?action=getCounterSections`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data[0]);
            setData2(resp.data.data[1]);
            setData3(resp.data.data[2]);
            setData4(resp.data.data[3]);

            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
  
      fetchData();
    }, []);
    return (
        <div className="counter-section w-100 float-left">
            <div className="counter-outer-layer">
                <div className="container">
                    <div className="counter-inner-sec">
                        <ul className="list-unstyled mb-0" data-aos="fade-up" data-aos-duration="700">
                            <li>
                                <figure>
                                    <img src={counterImg1} alt="counter-img1" />
                                </figure>
                                <div className="count d-inline-block">{data?.count}</div>
                                <span className="d-block client-status">{data?.title}</span>
                            </li>
                            <li>
                                <figure>
                                    <img src={counterImg2} alt="counter-img2" />
                                </figure>
                                <div className="count d-inline-block">{data2?.count}</div>
                                <span className="d-block client-status">{data2?.title}</span>
                            </li>
                            <li>
                                <figure>
                                    <img src={counterImg3} alt="counter-img3" />
                                </figure>
                                <div className="count d-inline-block">{data3?.count}</div>
                                <span className="d-block client-status">{data3?.title}</span>
                            </li>
                            <li>
                                <figure>
                                    <img src={counterImg4} alt="counter-img4" />
                                </figure>
                                <div className="count d-inline-block">{data4?.count}</div>
                                <span className="d-block client-status">{data4?.title}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterSection;
