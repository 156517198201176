// import React, { useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import '../assets/bootstrap/bootstrap.min.css';
// import '../assets/css/aos.css';
// import '../assets/css/custom.css';
// import '../assets/css/mobile.css';
// import '../assets/css/owl.carousel.css';
// import logo from '../assets/images/LPClogo.png';
// import phone from '../assets/images/phone.png';
// import { Link } from 'react-router-dom';

// function Header() {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const scrollToSection = (id) => {
//     navigate(`/#${id}`);
//   };

//   useEffect(() => {
//     if (location.hash) {
//       const elementId = location.hash.substring(1);
//       const scrollToElement = () => {
//         const element = document.getElementById(elementId);
//         if (element) {
//           element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//         }
//       };

//       // Add a slight delay to ensure content is fully loaded before scrolling
//       const timer = setTimeout(scrollToElement, 100); // 100ms delay

//       return () => clearTimeout(timer);
//     }
//   }, [location]);

//   return (
//     <div className="header-main-con w-100 float-left">
//       <div className="container-fluid">
//         <nav className="navbar navbar-expand-lg navbar-light p-0">
//           <Link className="navbar-brand" to="/">
//             <figure className="mb-0">
//               <img src={logo} width="300" alt="logo" />
//             </figure>
//           </Link>
//           <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span><span class="navbar-toggler-icon"></span><span class="navbar-toggler-icon"></span></button>
//           <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
//             <ul className="navbar-nav">
//               <li className="nav-item">
//                 <Link to="/" className="nav-link p-0" style={{fontSize: "17px"}} role="button" aria-haspopup="true" aria-expanded="false">
//                   Home
//                 </Link>
//               </li>
//               <li className="nav-item dropdown">
//                 <Link className="nav-link p-0" to="/services" role="button" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
//                   Our services
//                 </Link>
//                 <ul className="dropdown-menu">
//                   <li className="dropdown-submenu">
//                     <a className="dropdown-item dropdown-toggle">Clinical Services</a>
//                     <ul className="dropdown-menu">
//                       <li>
//                         <Link className="dropdown-item" to="/enhancedaccess">
//                           Enhanced Access Clinics
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to={"/cervicalscreening"}>
//                           Cervical Screening
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/longcovid">
//                           Long COVID Service
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/homecaremedicine">
//                           Care Home Medicines Training
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/virtualcarehome">
//                           Care Home Virtual Ward Rounds
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/vaccination">
//                           Vaccination Service
//                         </Link>
//                       </li>
//                     </ul>
//                   </li>
//                   <li className="dropdown-submenu">
//                     <a className="dropdown-item dropdown-toggle">Administrative Services</a>
//                     <ul className="dropdown-menu">
//                       <li>
//                         <Link className="dropdown-item" to="/workflow">
//                           Workflow Hub
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/prescriptionhub">
//                           Prescription Hub
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/claimservice">
//                           Claims Service
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/secretarialhub">
//                           Secretarial Hub
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/practicesupport">
//                           Practice Support Service
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/receptionsupport">
//                           Reception Support Service
//                         </Link>
//                       </li>
//                     </ul>
//                   </li>
//                   <li className="dropdown-submenu">
//                     <a className="dropdown-item dropdown-toggle">Professional Development and Training</a>
//                     <ul className="dropdown-menu">
//                       <li>
//                         <Link className="dropdown-item" to="/professionaltraining">
//                           Professional Training (CPD accredited)
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/corenavigation">
//                           Care Navigation Training
//                         </Link>
//                       </li>
//                       {/* <li>
//                         <Link className="dropdown-item" to="/">
//                           Quality Contract Support Service
//                         </Link>
//                       </li> */}
//                     </ul>
//                   </li>
//                   <li className="dropdown-submenu">
//                     <a className="dropdown-item dropdown-toggle">Workforce Support</a>
//                     <ul className="dropdown-menu">
//                       <li>
//                         <Link className="dropdown-item" to="/workforce">
//                           Workforce Deployment & Support Service
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/peoplemanage">
//                           People Management Services
//                         </Link>
//                       </li>
//                       <li>
//                         <Link className="dropdown-item" to="/arrs">
//                           ARRS
//                         </Link>
//                       </li>
//                     </ul>
//                   </li>
//                 </ul>
//               </li>
//               <li className="nav-item dropdown">
//                 <a className="nav-link dropdown-toggle p-0" href="" id="navbarDropdown5" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
//                   About
//                 </a>
//                 <div className="dropdown-menu" aria-labelledby="navbarDropdown5">
//                   <Link className="dropdown-item" to="/about">About us</Link>
//                   <Link className="dropdown-item" to="/board">Board</Link>
//                   {/* <Link className="dropdown-item" to="/team">Team</Link> */}
//                   {/* Add more about links here */}
//                 </div>
//               </li>
//               <li className="nav-item">
//                 <Link to="/successstories" className="nav-link p-0" role="button" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
//                   Success Stories
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link p-0" to="/lpcfederate" role="button" aria-haspopup="true" aria-expanded="false" style={{fontSize: "17px"}}>
//                   LPC Federate
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link p-0" to="/#contact" onClick={() => scrollToSection('contact')} style={{fontSize: "17px"}}>Contact</Link>
//               </li>
//             </ul>
//             <div className="header-contact d-flex align-items-center">
//               <div className="phone d-flex align-items-center">
//                 <figure className="mb-0">
//                   <img src={phone} alt="phone" />
//                 </figure>
//                 <div className="phone-txt">
//                   <span className="d-block">Call us now:</span>
//                   <a href="tel:01254961018">01254961018</a>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* <div className="cart-box">
//             <a href="#"><i className="fas fa-search"></i></a>
            
//           </div> */}
//         </nav>
//       </div>
//     </div>
//   );
// }

// export default Header;


import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import '../assets/bootstrap/bootstrap.min.css';
import '../assets/css/aos.css';
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import logo from '../assets/images/LPClogo.png';
import phone from '../assets/images/phone.png';

import CryptoJS from 'crypto-js';


function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const scrollToSection = (id) => {
    navigate(`/#${id}`);
    setIsNavbarOpen(false); // Close the navbar when scrolling to a section
  };

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const scrollToElement = () => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

      // Add a slight delay to ensure content is fully loaded before scrolling
      const timer = setTimeout(scrollToElement, 100); // 100ms delay

      return () => clearTimeout(timer);
    }
  }, [location]);

  const handleNavbarToggle = () => {
    setIsNavbarOpen((prev) => !prev); // Toggle the navbar open/close state
  };

  const handleLinkClick = () => {
    setIsNavbarOpen(false); // Collapse the navbar on link click
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);


  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };
    
  useEffect(() => {
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://local-primary-care.webitaltech.com/api/Get-LPC-Data.php?action=getCategoriesWithServices`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
  
      fetchData();
    }, []);

  return (
    <div className="header-main-con w-100 float-left">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <Link className="navbar-brand" to="/">
            <figure className="mb-0">
              <img src={logo} width="300" alt="logo" />
            </figure>
          </Link>

          {/* Navbar toggler button */}
          <button 
            className={`navbar-toggler ${isNavbarOpen ? '' : 'collapsed'}`}
            type="button" 
            onClick={handleNavbarToggle}
            aria-expanded={isNavbarOpen} 
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>

          </button>

          {/* Navbar menu */}
          <div className={`navbar-collapse justify-content-end ${isNavbarOpen ? 'show' : 'collapse'}`} id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link p-0" style={{ fontSize: '17px' }} onClick={handleLinkClick}>
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle p-0" to="/services" role="button" aria-haspopup="true" aria-expanded={isNavbarOpen} style={{ fontSize: '17px' }}>
                  Our Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {
                    data?.map((item,index)=>{
                      return(   <li className="dropdown-submenu" key={index}>
                        <a className="dropdown-item dropdown-toggle">{item.category_name}</a>
                        <ul className="dropdown-menu">
                          {
                            item.services.map((item, ind)=>{
                                return( <li><Link className="dropdown-item" to={`/servivedetails/${item.service_id}`} onClick={handleLinkClick}>{item.service_name}</Link></li>

                                )
                            })
                          }
                  
                          {/* <li><Link className="dropdown-item" to="/servivedetails/35" onClick={handleLinkClick}>Cervical Screening</Link></li>
                          <li><Link className="dropdown-item" to="/servivedetails/36" onClick={handleLinkClick}>Long COVID Service</Link></li>
                          <li><Link className="dropdown-item" to="/servivedetails/37" onClick={handleLinkClick}>Care Home Medicines Training</Link></li>
                          <li><Link className="dropdown-item" to="/servivedetails/38" onClick={handleLinkClick}>Care Home Virtual Ward Rounds</Link></li>
                          <li><Link className="dropdown-item" to="/servivedetails/39" onClick={handleLinkClick}>Vaccination Service</Link></li> */}
                        </ul>
                      </li>

                      )
                    })
                  }
                  {/* Clinical Services */}
               
                  {/* Additional dropdown items here */}
                  {/* <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Administrative Services</a>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item" to="/servivedetails/40" onClick={handleLinkClick}>Workflow Hub</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/41" onClick={handleLinkClick}>Prescription Hub</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/42" onClick={handleLinkClick}>Claims Service</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/43" onClick={handleLinkClick}>Secretarial Hub</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/44" onClick={handleLinkClick}>Practice Support Service</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/45" onClick={handleLinkClick}>Reception Support Service</Link></li>
                    </ul>
                  </li> */}
                  {/* <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Professional Development and Training</a>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item" to="/servivedetails/46" onClick={handleLinkClick}>Professional Training (CPD accredited)</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/47" onClick={handleLinkClick}>Care Navigation Training</Link></li>
                      <li><Link className="dropdown-item" to="/" onClick={handleLinkClick}>Quality Contract Support Service</Link></li>
                    </ul>
                  </li> */}
                  {/* <li className="dropdown-submenu">
                    <a className="dropdown-item dropdown-toggle">Workforce Support</a>
                    <ul className="dropdown-menu">
                      <li><Link className="dropdown-item" to="/servivedetails/48" onClick={handleLinkClick}>Workforce Deployment & Support Service</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/49" onClick={handleLinkClick}>People Management Services</Link></li>
                      <li><Link className="dropdown-item" to="/servivedetails/50" onClick={handleLinkClick}>ARRS</Link></li>
                    </ul>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle p-0" to="#" role="button" aria-haspopup="true" aria-expanded={isNavbarOpen} style={{ fontSize: '17px' }}>
                  About
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown5">
                  <Link className="dropdown-item" to="/about" onClick={handleLinkClick}>About Us</Link>
                  <Link className="dropdown-item" to="/board" onClick={handleLinkClick}>Board</Link>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/successstories" className="nav-link p-0" style={{ fontSize: '17px' }} onClick={handleLinkClick}>
                  Success Stories
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-0" to="/lpcfederate" style={{ fontSize: '17px' }} onClick={handleLinkClick}>
                  LPC Federate
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link p-0" to="/#contact" onClick={() => { scrollToSection('contact'); handleLinkClick(); }} style={{ fontSize: '17px' }}>
                  Contact
                </Link>
              </li>
            </ul>
            <div className="header-contact d-flex align-items-center">
              <div className="phone d-flex align-items-center">
                <figure className="mb-0">
                  <img src={phone} alt="phone" />
                </figure>
                <div className="phone-txt">
                  <span className="d-block">Call us now:</span>
                  <a href="tel:01254961018">01254961018</a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
