import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/professionaltraining.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Professionaltraining = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Professional Training (CPD accredited)"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
  Our Professional Training (CPD Accredited) Service is designed to support the
  continuous professional development of healthcare practitioners. We offer a
  wide range of training opportunities tailored to meet the evolving needs of
  healthcare professionals. <br />
  <br />
  Our training courses cover various topics, including clinical skills, patient
  safety, healthcare management, and emerging medical technologies. Delivered by
  expert trainers and industry leaders, our programs provide participants with
  the latest insights and practical skills they can apply in their daily
  practice. Whether you're a general practitioner, nurse, pharmacist, or allied
  health professional, our CPD-accredited courses are designed to enhance your
  expertise and professional growth.
  <br />
  <br />
  Each training session is structured to meet CPD accreditation standards,
  ensuring participants earn the necessary credits to maintain their
  professional certifications and requirements, improve their clinical practice,
  and ultimately provide better patient care.
  <br />
  <br />
  Our commitment to quality education and professional excellence empowers
  healthcare professionals to continue their lifelong learning journey with
  confidence and support.
</p>





              </div>
            </div>
          </div>
 
        </div>
      </div>

          <ServiceSideSection />

    </section>
     </>

  );
};

export default Professionaltraining;
