import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/peoplemanagement.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Peoplemanagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"People Management Services"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
  
              <p className="text-size-16">
  Our People Management Services Team is where expertise meets compassion. The
  team, led by a seasoned People Manager with Chartered FCIPD- level 7
  accreditation and supported by a dedicated People Advisor with ACIPD-level 5
  qualification, boasts a collective experience of 43 years in HR, along with
  specialised training in Mental Health First Aid and Employee Wellbeing. <br />
  <br />
  Serving over 160 LPC employees and extending our services to GP practices
  across Blackburn with Darwen, we offer comprehensive support throughout the
  employee lifecycle. Our services encompass: <br />
  <br />
  Resourcing and Talent Management Learning and Development Employment Law
  Advice and Compliance Employee Health, Wellbeing, and Satisfaction Workforce
  Strategy Development and Implementation Benchmarking and Job Evaluation
  Performance Management With a focus on excellence and a commitment to
  nurturing thriving workplaces, our team is dedicated to empowering
  organisations and employees alike.{" "}
</p>







              </div>
            </div>
          </div>
   
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Peoplemanagement;
