import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/seceteriat.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Secretarial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Secretarial Hub"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
  The Secretarial Hub is your centralised solution for efficient referral letter
  processing on behalf of GP practices. <br />
  <br /> Our skilled secretarial coordinators specialise in creating, typing,
  and sending referral letters, ensuring seamless continuity of patient care.
  With a structured approach, our team manages a diverse range of referral
  letter requests with precision and professionalism, leaving your practice free
  to focus on delivering exceptional healthcare.
</p>




              </div>
            </div>
          </div>
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Secretarial;
