import React, { useEffect } from 'react';
import claims from '../assets/images/Homeimages/claims.jpg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import "./services.css"
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
// import '../assets/css/shop.css';

import img1 from "../assets/images/Homeimages/about-img.jpg"
import img2 from "../assets/images/Homeimages/about-video-img.jpg"
import img3 from "../assets/images/video-play-icon.png"
import PortfolioCarousel from './careServices/careservices';
import ServiceCarousel from './Whatwecando';
import AOS from 'aos';
import ProjectCarousel from './latestProjects';
import { Link } from 'react-router-dom';
function ServiceSectionfed() {
    useEffect(() => {
        AOS.init({
            //  duration: 1000,
             once: false,
           })
     }, [])

    //  function lightbox_open() {
    //     document.getElementById('lightbox').style.display = 'block';
    //   }
      
    //   function lightbox_close() {
    //     document.getElementById('lightbox').style.display = 'none';
    //   }
      

    return (
        <>    
        <section className="index3-service-section w-100 float-left padding-top padding-bottom">
      <div className="container">
        <div className="generic-title text-center">
          <h2 data-aos="fade-up" className='' style={{color: "black"}} data-aos-duration="700"> Our Member Practices</h2>
        </div>
        <ServiceCarousel />
        </div>
        </section>
  </>
        
    );
}

export default ServiceSectionfed;
