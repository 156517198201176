import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/cer.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const CervicalScreen = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Cervical Screening"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
              Our organisation is committed to promoting women's health through comprehensive cervical screening services, designed to detect early signs of cervical cancer and ensure timely intervention. Our programs are tailored to meet the needs of healthcare professionals working in care homes, women’s health centres, and rehabilitation services, focusing on the importance of early detection and preventive care.
<br />
<br />
We provide training on the entire cervical screening process, from understanding the significance of regular screenings to mastering the technical skills required for sample collection and patient communication. Our courses cover best practices in screening procedures, handling results, and ensuring patient comfort and confidentiality.
<br/>
<br/>
With a combination of expert-led workshops, practical demonstrations, and in-depth guidance, we equip healthcare providers with the confidence and knowledge needed to perform cervical screenings effectively. By fostering a proactive approach to women's health, our training contributes to the early detection and treatment of cervical abnormalities, improving outcomes for women across various care settings.
</p>




              </div>
            </div>
          </div>
 
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default CervicalScreen;
