import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/vaccination.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';

const VaccinationService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Vaccination Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
  Our Vaccination Service is a comprehensive solution dedicated to safeguarding
  the health and well-being of individuals and communities through a wide range
  of approved immunisation programs. Vaccinations are a vital part of preventive
  healthcare, offering protection against a diverse array of infectious
  diseases. <br />
  <br />
  Our service ensures eligible patients have access to a comprehensive range of
  vaccines, providing a one-stop solution for patient needs.
  <br />
  <br />
  At our vaccination clinics, experienced healthcare professionals administer
  vaccines in a safe and welcoming environment. We follow the latest guidelines
  and recommendations from public health authorities to ensure the efficacy and
  safety of all immunisations. <br />
  <br />
  We aim to make vaccination as accessible and convenient as possible with
  flexible scheduling, walk-in appointments, and community outreach programs.
  <br />
  <br />
  Education is a critical component of our Vaccination Service. We provide
  patients with detailed information about each vaccine's benefits and potential
  side effects, addressing any concerns and questions they may have. <br />
  <br />
  By promoting the importance of immunisation and maintaining high vaccination
  rates, we contribute to the overall health of our community, helping to
  prevent outbreaks and protect vulnerable populations.
</p>

              </div>
            </div>
          </div>
     
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default VaccinationService;
