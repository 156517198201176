import React from 'react'
import teamImg1 from '../assets/images/Homeimages/team-img1.jpeg';
import teamImg2 from '../assets/images/Homeimages/team-img2.jpeg';
import teamImg3 from '../assets/images/Homeimages/team-img3.jpeg';
import teamImg4 from '../assets/images/Homeimages/team-img4.jpeg';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
import { Link } from 'react-router-dom';
const Team= () => {
    const teamMembers = [
        {
            name: 'Alice Waters',
            title: 'Sycho Founder',
            imgSrc: teamImg1,
            imgAlt: 'team-img1',
            description: 'Nuis aute irure dolor reprehenderit in volutae velit esse fugiat...',
            socialLinks: [
                { href: 'https://www.facebook.com/', iconClass: 'fab fa-facebook-f' },
                { href: 'https://twitter.com/', iconClass: 'fab fa-twitter' },
                { href: 'https://www.linkedin.com/', iconClass: 'fab fa-linkedin-in' }
            ]
        },
        {
            name: 'Jamie Oliver',
            title: 'Therapy Expert',
            imgSrc: teamImg2,
            imgAlt: 'team-img2',
            description: 'Nuis aute irure dolor reprehenderit in volutae velit esse fugiat...',
            socialLinks: [
                { href: 'https://www.facebook.com/', iconClass: 'fab fa-facebook-f' },
                { href: 'https://twitter.com/', iconClass: 'fab fa-twitter' },
                { href: 'https://www.linkedin.com/', iconClass: 'fab fa-linkedin-in' }
            ]
        },
        {
            name: 'Clare Smyth',
            title: 'Sycho Founder',
            imgSrc: teamImg3,
            imgAlt: 'team-img3',
            description: 'Nuis aute irure dolor reprehenderit in volutae velit esse fugiat...',
            socialLinks: [
                { href: 'https://www.facebook.com/', iconClass: 'fab fa-facebook-f' },
                { href: 'https://twitter.com/', iconClass: 'fab fa-twitter' },
                { href: 'https://www.linkedin.com/', iconClass: 'fab fa-linkedin-in' }
            ]
        },
        {
            name: 'Hekim Rswana',
            title: 'Therapy Expert',
            imgSrc: teamImg4,
            imgAlt: 'team-img4',
            description: 'Nuis aute irure dolor reprehenderit in volutae velit esse fugiat...',
            socialLinks: [
                { href: 'https://www.facebook.com/', iconClass: 'fab fa-facebook-f' },
                { href: 'https://twitter.com/', iconClass: 'fab fa-twitter' },
                { href: 'https://www.linkedin.com/', iconClass: 'fab fa-linkedin-in' }
            ]
        }
    ];
  return (
    <>
     {/* BANNER SECTION START */}
     <section className="sub-banner-section-team w-100 float-left d-flex align-items-center">
                <div className="container">
                    <div className="sub-banner-inner-con text-center">
                        <h1 data-aos="fade-up" data-aos-duration="700">Team</h1>
                        <p data-aos="fade-up" data-aos-duration="700">
                            Meet the dedicated professionals driving excellence in patient care at Local Primary Care.
                        </p>
                        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration="700">
                            <ol className="breadcrumb d-inline-block mb-0">
                                <li className="breadcrumb-item d-inline-block"><Link to="/about">About</Link></li>
                                <li className="breadcrumb-item active text-white d-inline-block" aria-current="page">Team</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            {/* BANNER SECTION END */}

            {/* TEAM SECTION START */}
            <section className="team-main-section w-100 float-left padding-top padding-bottom light-bg">
                <div className="container">
                    <div className="generic-title text-center" data-aos="fade-up" data-aos-duration="700">
                        <span className="small-text">Team Members</span>
                        <h2 className="mb-0">Our Professional Team</h2>
                    </div>
                    <div className="team-inner-section">
                        {teamMembers.map((member, index) => (
                            <div className="member-con text-center" data-aos="fade-up" data-aos-duration="700" key={index}>
                                <div className="member-img-details position-relative">
                                    <figure>
                                        <img src={member.imgSrc} alt={member.imgAlt} />
                                    </figure>
                                    <div className="member-detail-box">
                                        <ul className="list-unstyled">
                                            {member.socialLinks.map((link, idx) => (
                                                <li className="d-inline-block" key={idx}>
                                                    <a href={link.href}><i className={link.iconClass}></i></a>
                                                </li>
                                            ))}
                                        </ul>
                                        <p>{member.description}</p>
                                        <div className="generic-btn">
                                            <a href="contact">Book Appointment</a>
                                        </div>
                                    </div>
                                </div>
                                <h4>{member.name}</h4>
                                <span className="d-block">{member.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* TEAM SECTION END */}

    </>
  )
}

export default Team