import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/workforce.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const WorkForcedeploy = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Workforce Deployment & Support Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
              Maximise efficiency and productivity within your healthcare setting with our Workforce Deployment & Support Service. Designed to meet the growing demands of modern healthcare environments, we provide tailored solutions to help you deploy the right staff in the right roles at the right time.
              <br />   <br /> Our dedicated workforce management team works closely with you to create a sustainable deployment strategy, enabling you to meet both short-term and long-term healthcare goals. Let us help you optimise your staffing, enhance patient care, and support your team’s growth.
</p>

              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default WorkForcedeploy;
