import React from 'react'
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import { Link } from 'react-router-dom';


const BannerDynammic = ({had1}) => {
  return (
    <>
    {/* BANNER SECTION START */}
  <section className="service-banner sub-banner-section w-100 float-left d-flex align-items-center">
  <div className="container">
    <div className="sub-banner-inner-con text-center">
      <h1 data-aos="fade-up" data-aos-duration={700}>
       {had1}
      </h1>
      {/* <p data-aos="fade-up" data-aos-duration="700">Boidunt eget semper nec ruam sed hendrerit morbi aeu feliseao augue <br>
            pellentesue veniam morbi acer.</p> */}
      <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration={700}>
        <ol className="breadcrumb d-inline-block mb-0">
          <li className="breadcrumb-item d-inline-block">
            <Link to={"/"}>Home</Link>
          </li>
          <li
            className="breadcrumb-item active text-white d-inline-block"
            aria-current="page"
          >
            Our Services
          </li>
        </ol>
      </nav>
    </div>
  </div>
</section>
{/* BANNER SECTION END */}
</>
  )
}

export default BannerDynammic