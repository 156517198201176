import React, { useEffect, useState } from 'react';

// Assuming you have imported images correctly
import enhancedImg from '../../assets/images/Homeimages/enhanced.webp';
import cervicalScreeningImg from '../../assets/images/Homeimages/cer.webp';
import longCovidImg from '../../assets/images/Homeimages/longcovid.webp';
import careHomeMedicinesImg from '../../assets/images/Homeimages/carehomemedicines.webp';
import careHomeImg from '../../assets/images/Homeimages/carehomemed.webp';
import vaccinationImg from '../../assets/images/Homeimages/vaccination.webp';
import workflowImg from '../../assets/images/Homeimages/workflow.webp';
import prescriptionImg from '../../assets/images/Homeimages/Prescription.webp';
import claimsImg from '../../assets/images/Homeimages/claimservice.webp';
import secretarialImg from '../../assets/images/Homeimages/seceteriat.webp';
import practiceSupportImg from '../../assets/images/Homeimages/practicesupport.webp';
import telephoneImg from '../../assets/images/Homeimages/reception.webp';
import professionalTrainingImg from '../../assets/images/Homeimages/professionaltraining.webp';
import workforce from '../../assets/images/Homeimages/workforce.webp';
import peoplemanagement from '../../assets/images/Homeimages/peoplemanagement.webp';
import arrImg from '../../assets/images/Homeimages/arrs.webp'; // Assuming this is the same image used for ARRS and People Management
import corenavi from '../../assets/images/Homeimages/corenavi.webp';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import CarehomeMedicines from '../carehomemedicines';

// const servicesData = [
//   {
//     title: 'Clinical Services',
//     items: [
//       { name: 'Enhanced Access GP Clinics', image: enhancedImg, link: 'enhancedaccess' },
//       { name: 'Cervical Screening', image: cervicalScreeningImg, link: 'cervicalscreening' },
//       { name: 'Long Covid Service', image: longCovidImg, link: 'longcovid' },
//       { name: 'Care Home Medicines Training', image: prescriptionImg, link: 'homecaremedicine' },
//       { name: 'Care Home Virtual Ward Rounds', image: careHomeImg, link: 'virtualcarehome' },
//       { name: 'Vaccination Service', image: vaccinationImg, link: 'vaccination' }
//     ]
//   },
//   {
//     title: 'Administrative Services',
//     items: [
//       { name: 'Workflow Hub', image: workflowImg, link: 'workflow' },
//       { name: 'Prescription Hub', image: careHomeMedicinesImg, link: 'prescriptionhub' },
//       { name: 'Claims Service', image: claimsImg, link: 'claimservice' },
//       { name: 'Secretarial Hub', image: secretarialImg, link: 'secretarialhub' },
//       { name: 'Practice Support Services', image: practiceSupportImg, link: 'practicesupport' },
//       { name: 'Reception Support Service', image: telephoneImg, link: 'receptionsupport' }
//     ]
//   },
//   {
//     title: 'Professional Development and Training',
//     items: [
//       { name: 'Professional Training (CPD accredited)', image: professionalTrainingImg, link: 'professionaltraining' },
//       { name: 'Care Navigation Training', image: corenavi, link: 'corenavigation' }, // Example, replace with actual image

//     ]
//   },
//   {
//     title: 'Workforce Support',
//     items: [
//       { name: 'Workforce Deployment & Support Service', image: workforce, link: 'workforce' }, // Example, replace with actual image
//       { name: 'People Management Services (HR Management)', image: peoplemanagement, link: 'peoplemanage' },
//       { name: 'ARRS', image: arrImg, link: 'arrs' }
//     ]
//   }
// ];



const ServiceSideSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState({ categoryIndex: null, itemIndex: null });
  const [data, setData] = useState([]);
  const encryptToken = (token, key, iv) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key);
    const ivHex = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
    return encrypted.toString();
  };
  
  useEffect(() => {
    const key = '8347383272422213';
    const iv = '1234567890123456';
    const token = 'Pass.lpc#webitaltech';
  
    const encryptedToken = encryptToken(token, key, iv);
    const fetchData = async () => {
      try {
        const response = await fetch(`https://local-primary-care.webitaltech.com/api/Get-LPC-Data.php?action=getCategoriesWithServices`,
          {
            method: 'GET',
            headers: {
              Authorization: encryptedToken,
              'Content-Type': 'application/json',
            },
            referrerPolicy: 'no-referrer',
          }
        );
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const resp = await response.json();
        if (resp.status === 'error') {
          console.error('Error:', resp.message);
        } else {
          setData(resp.data.data);
          // console.log(resp);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="services-main-section w-100 float-left padding-top light-bg">
    <div className="container">
      <div className="generic-title text-center">
        <span className="small-text" data-aos="fade-up" data-aos-duration="700">Our Services</span>
        <h2 className="mb-0" data-aos="fade-up" data-aos-duration="700">Explore Our Services</h2>
      </div>

      {/* Iterate through each category in servicesData */}
      {data?.map((category, categoryIndex) => (
        <div key={categoryIndex} className="service-category-section mt-5">
          {/* Category Title */}
          <h3 className="category-title text-center mt-5" data-aos="fade-up" data-aos-duration="700">{category.category_name}</h3>

          <div className="team-inner-section four-member-in-row mt-2">
            {category.services.map((item, itemIndex) => (
              <div key={itemIndex} className="member-con text-center"
                   onMouseEnter={() => setHoveredIndex({ categoryIndex, itemIndex })}
                   onMouseLeave={() => setHoveredIndex({ categoryIndex: null, itemIndex: null })}
                   style={{
                      cursor: "pointer",
                      position: "relative",
                      overflow: "hidden",
                   }}>
                    <Link to={`/servivedetails/${item.service_id}`}>
                <div className="member-img-details position-relative">
                  <figure data-aos="fade-up" data-aos-duration="700" style={{ position: "relative", margin: 0 }}>
                    <img
                      src={`https://local-primary-care.webitaltech.com/public/images/service/${item.service_image}`}
                      alt={item.service_id}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "10px"
                      }}
                    />
                    {/* Overlay that appears on hover */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent overlay
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: hoveredIndex.categoryIndex === categoryIndex && hoveredIndex.itemIndex === itemIndex ? 1 : 0, // Conditionally show overlay on hover
                        transition: "opacity 0.3s ease", // Smooth transition
                        borderRadius: "10px",
                      }}
                    >
                      <p style={{ margin: 0, fontSize: "16px", fontWeight: "bold" }}>
                        {item.service_name}
                      </p>
                    </div>
                  </figure>
                </div>
                </Link>
                <Link to={`/${item.service_id}`}><h4 data-aos="fade-up" data-aos-duration="700">{item.service_name}</h4></Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </section>


  );
};

export default ServiceSideSection;
