import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import longCovidImg from '../../assets/images/Homeimages/longcovid.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Longcovid = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
  
<BannerDynammic had1={"Long Covid Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={longCovidImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
                <p className="text-size-16">
                  LPC provide essential representation on the Long Covid Service Multidisciplinary Team, ensuring comprehensive support for individuals navigating the challenges of post-Covid recovery. <br /><br />
                  The Long Covid Service is a therapy-led initiative dedicated to supporting patients in managing the lingering symptoms of post-Covid syndrome. The interdisciplinary team comprises Occupational Therapists, Speech and Language Therapists, Respiratory Physiotherapists, and Clinical Psychologists, collaborating with GPs and hospital Consultants specialising in Long Covid. With a focus on personalised care, we empower patients to develop effective strategies for symptom management.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-7 col-md-12 col-sm-12 col-12 column mx-auto">
          
          </div> */}
   

        </div>

      </div>
      <ServiceSideSection />
    </section>
    </>

  );
};

export default Longcovid;
