import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/carehomemed.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const CarehomeMedicines = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Care Home Medicines Training"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
                <p className="text-size-16">
                  Our organisation specialises in delivering comprehensive medicines-based training programs tailored for care homes, children's centres, and rehabilitation services. With a dedicated focus on ensuring the safe and effective handling of medications, our courses cover every aspect of medicine administration, storage, and management. <br /><br /> From understanding dosage calculations to implementing proper storage protocols, our training equips staff with the knowledge and skills needed to maintain the highest standards of medication safety and compliance. <br /><br /> Through hands-on learning experiences, interactive workshops, and expert guidance, we empower caregivers to confidently navigate the complexities of medication administration, ultimately enhancing the quality of care provided to residents and clients in these vital facilities.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 column">
          </div> */}
        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default CarehomeMedicines;
