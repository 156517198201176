import React from 'react'
import Header from '../Components/Header'
import BannerSection from '../Components/Banner'
import ServiceSection from '../Components/Services'
import CounterSection from '../Components/counterSection'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import ProcessContSection from '../Components/formContcomp'
import TeamSection from '../Components/teamSection'
import TestimonialSection from '../Components/testiminials'
import FooterSection from '../Components/Footer'
import video from '../assets/BannerBgVideo/bg.webm';
const Home = () => {
  return (
    <>
    <BannerSection video={video}/>
    <ServiceSection />
    <CounterSection />  
    <ProcessContSection />
    <TeamSection />
    <TestimonialSection />
    </>
  )
}

export default Home