import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/claimservice.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const ClaimService = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Claims Service"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
              Empower your GP practice with our Claims Service, a centralised solution dedicated to managing local and direct enhanced services claims seamlessly. Our expert claims administrators handle the entire process, from initiating claims to processing them monthly, ensuring accuracy and timely payments for your practice. Trust us to navigate the complexities of enhanced service and CQRS claims, leaving you free to focus on providing exceptional patient care. 
</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 column">

          </div> */}
        </div>
      </div>
      <ServiceSideSection />
    </section>
     </>

  );
};

export default ClaimService;
