import React, { useEffect } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import careHomeMedicinesImg from '../../assets/images/Homeimages/workflow.webp';
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';


const Workflowhub = () => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []); 
  return (
    <>
<BannerDynammic had1={"Workflow hub"}/>
<section className="singleblog-section blogpage-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1" data-aos="fade-up" data-aos-duration="700">
                <img src={careHomeMedicinesImg} alt="" className="img-fluid" />
              </figure>
              <div className="content1" data-aos="fade-up" data-aos-duration="700">
              <p className="text-size-16">
          Our Workflow Hub is a centralised solution for streamlined processing of clinical documents and read coding information for GP practices. Our proficient workflow coordinators specialise in coding and actioning clinical letters received, ensuring efficient management within the workflow hub. With a structured approach, our team handles high volumes of clinical documents, managing key actions seamlessly to support your practice in delivering quality patient care. 
</p>


              </div>
            </div>
          </div>

        </div>
      </div>
      <ServiceSideSection />

    </section>
     </>

  );
};

export default Workflowhub;
